<template>
  <div class="port-info-detail-add">
    <van-row>
      <van-col>
        <div class="baseInfo">
          港口基本信息
        </div>
      </van-col>
    </van-row>
    <van-form @submit="savePortInfo()" alidate-first @failed="onFailed" :rules="portInfoForm">
      <van-row>
        <van-col span="24">
          <div class="name">
            <van-cell-group>
              <van-field v-model="portInfo.portName" :rules="portInfoForm.portName" input-align="right" label="*码头名称:"/>
            </van-cell-group>
          </div>
          <div class="quantity">
            <van-row>
              <van-col span="23">
                <van-cell-group>
                  <van-field placeholder="请输入数字" v-model="portInfo.berthNo" input-align="right" type="number"
                             label="*泊位数量:"/>
                </van-cell-group>
              </van-col>
              <van-col span="1">
                <div class="span1">个</div>
              </van-col>
            </van-row>
          </div>
          <div class="draft">
            <van-row>
              <van-col span="23">
                <van-cell-group>
                  <van-field placeholder="请输入数字" v-model="portInfo.draught" input-align="right" type="number"
                             label="*吃水:"/>
                </van-cell-group>
              </van-col>
              <van-col span="1">
                <div class="span">m</div>
              </van-col>
            </van-row>
          </div>
          <div class="length">
            <van-row>
              <van-col span="23">
                <van-cell-group>
                  <van-field placeholder="请输入数字" v-model="portInfo.berthLength" input-align="right" type="number"
                             label="*码头/泊位长度:"/>
                </van-cell-group>
              </van-col>
              <van-col span="1">
                <div class="span">m</div>
              </van-col>
            </van-row>
          </div>
          <div class="shipType">
            <van-row>
              <van-col span="23">
                <van-cell-group>
                  <van-field placeholder="请输入数字" v-model="portInfo.vesselType" input-align="right" type="number"
                             label="*船型:"/>
                </van-cell-group>
              </van-col>
              <van-col span="1">
                <div class="span">t</div>
              </van-col>
            </van-row>
          </div>
          <div class="length">
            <van-cell-group>
              <van-field placeholder="室内/室外" v-model="portInfo.store" input-align="right" label="*仓储:"/>
            </van-cell-group>
          </div>
          <div class="duration">
            <van-cell-group>
              <van-field placeholder="12小时/24小时" v-model="portInfo.duration" input-align="right" label="*标准作业时长:"/>
            </van-cell-group>
          </div>
        </van-col>
      </van-row>
      <van-button type="primary" size="large" class="footer-bar">保存</van-button>
      <!--      <crm-tab-bar :tabpage="5"/>-->
    </van-form>
  </div>
</template>
<script>
import CrmTabBar from "@/components/CrmTabBar";

export default {
  name: "BulkCrmsShipInfoDetailAdd",
  components: {CrmTabBar},
  data() {
    return {
      portInfo: {
        portName: '',
        berthNo: '',
        draught: '',
        berthLength: '',
        vesselType: '',
        store: '',
      },
      portInfoForm: {
        portName: [
          {required: true, message: '请输入港口名称', trigger: 'blur'},
        ],
        berthNo: [
          {required: true, message: '请输入泊位数量', trigger: 'blur'},
        ],
        draught: [
          {required: true, message: '请输入吃水', trigger: 'blur'},
        ],
        berthLength: [
          {required: true, message: '请输入码头/泊位长度', trigger: 'blur'},
        ],
        vesselType: [
          {required: true, message: '请输入船型', trigger: 'blur'},
        ],
        store: [
          {required: true, message: '请输入仓储', trigger: 'blur'},
        ],
        duration: [
          {required: true, message: '请输入标准作业时长', trigger: 'blur'},
        ],
      },
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
    },
    savePortInfo() {
      console.log(this.portInfo)
      this.$ajax.post('/api/ajax/bulkCrmPortinfo/saveBulkPortinfotwo.json',
          {
            portName: this.portInfo.portName,
            berthNo: this.portInfo.berthNo,
            draught: this.portInfo.draught,
            berthLength: this.portInfo.berthLength,
            vesselType: this.portInfo.vesselType,
            store: this.portInfo.store,
            duration: this.portInfo.duration,
            portOtherid: this.$route.params.id,
            createdPeople: this.$store.getters.userId,
          }).then(res => {
        if (res.status == 200) {
          this.$message({
            showClose: true,
            message: '添加成功',
            type: 'success'
          });
          sessionStorage.clear();
          setTimeout(() => {
            this.$router.replace({path: '/BulkCrmsPortInfoList/' + this.$route.params.id})
          }, 200);
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('添加失败');
      })
    },
    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },
  }
}
</script>
<style scoped>
.port-info-detail-add {
  background: white;
}

.port-info-detail-add .baseInfo {
  font-size: 10px;
  color: #BAB8B7;
  margin-top: 10px;
  padding: 15px;
}

.port-info-detail-add .van-button {
  width: 220px;
  height: 35px;
  border-radius: 5px;
  font-weight: bold;
  font-family: 新宋体;
  margin-top: 130px;
}

.port-info-detail-add .span {
  padding-top: 13px;
  margin-left: -2px;
}

.port-info-detail-add .span1 {
  padding-top: 12px;
  margin-left: -2px;
  font-size: 14px;
}

.port-info-detail-add .total .van-cell {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 10px 10px 16px;;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}
</style>
<style>
.port-info-detail-add .duration .van-field__label, .length .van-field__label {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  flex: none;
  box-sizing: border-box;
  width: 8.2em;
  margin-right: 12px;
  color: #646566;
  text-align: left;
  word-wrap: break-word;
}

.port-info-detail-add .remarks .van-field__control--right, .post .van-field__control--right, .contacts .van-field__control--right, .customer .van-field__control--right, .model .van-field__control--right {
  color: #BAB8B7;
}
</style>
